import { authorizedSignatorySchema, facilitySchema } from "@src/appV2/Facilities/types";
import { TimeSlot } from "@src/appV2/redesign/Shift/Open/types";
import { z } from "zod";

export enum ShiftPeriod {
  AM = "am",
  PM = "pm",
  NOC = "noc",
}

export const ShiftPeriodContent: Record<ShiftPeriod, string> = {
  [ShiftPeriod.AM]: "AM",
  [ShiftPeriod.PM]: "PM",
  [ShiftPeriod.NOC]: "NOC",
};

export const shiftPeriodToTimeSlot: Record<ShiftPeriod, TimeSlot> = {
  [ShiftPeriod.AM]: TimeSlot.AM,
  [ShiftPeriod.PM]: TimeSlot.PM,
  [ShiftPeriod.NOC]: TimeSlot.NOC,
};

export const timeSlotToShiftPeriod: Record<TimeSlot, ShiftPeriod> = {
  [TimeSlot.AM]: ShiftPeriod.AM,
  [TimeSlot.PM]: ShiftPeriod.PM,
  [TimeSlot.NOC]: ShiftPeriod.NOC,
};

export enum UrgencyType {
  NCNS = 1,
  LATE_CANCELLATION = 2,
  LAST_MINUTE = 3,
  /**
   * The server has `UrgentShiftUrgencyType` for the above three, but we see 4 for some responses
   * on `api/v1/shifts/myShifts/nextTwoDays` responses, which come directly from the db.
   */
  UNKNOWN = 4,
}

export enum ShiftWindow {
  A_TEAM = "A-TEAM",
  CLIPBOARD_SCORE_FIRST = "CLIPBOARD_SCORE_FIRST",
  CLIPBOARD_SCORE_SECOND = "CLIPBOARD_SCORE_SECOND",
  FAMILIAR_WORKERS = "FAMILIAR_WORKERS",
  RELIABILITY_RANK = "RELIABILITY_RANK",
}

export enum NfcTagRequestStatus {
  REQUESTED = "REQUESTED",
  ON_ITS_WAY = "ON_ITS_WAY",
  SET_UP = "SET_UP",
  DELETED = "DELETED",
}

const nfcTagRequestSchema = z.object({
  tagStatus: z.nativeEnum(NfcTagRequestStatus),
  tagLocation: z.string().optional(),
});

export enum InstantPayTimeLogStates {
  ASSIGNED = "ASSIGNED",
  ASSIGNED_FAILED = "ASSIGNED_FAILED",
  CLOCK_IN = "CLOCK_IN",
  CLOCK_OUT = "CLOCK_OUT",
  LUNCH_IN = "LUNCH_IN",
  LUNCH_OUT = "LUNCH_OUT",
  ADMIN_CLOCK = "ADMIN_CLOCK",
  ADMIN_LUNCH = "ADMIN_LUNCH",
  PARTIALY_PAID = "PARTIALY_PAID",
  FULL_PAID = "FULL_PAID",
  CANCELLED = "CANCELLED",
  VERIFIED = "VERIFIED",
  TIME_ADJUSTED = "TIME_ADJUSTED",
  SKIP_LUNCH = "SKIP_LUNCH",
  SHIFT_NOTE_UPDATE = "SHIFT_NOTE_UPDATE",
  SHIFT_TIME_CHANGE = "SHIFT_TIME_CHANGE",
  POST_VERIFY_CLOCK_IN = "POST_VERIFY_CLOCK_IN",
  POST_VERIFY_CLOCK_OUT = "POST_VERIFY_CLOCK_OUT",
  POST_VERIFY_LUNCH_IN = "POST_VERIFY_LUNCH_IN",
  POST_VERIFY_LUNCH_OUT = "POST_VERIFY_LUNCH_OUT",
  AUTO_VERIFY = "AUTO_VERIFY",
  BREAK_EXTRA_WORKED_TIME_PAID = "BREAK_EXTRA_WORKED_TIME_PAID",
}

export enum InstantPayPaymentTypes {
  INITIAL = "INITIAL",
  REMAINING = "REMAINING",
  BREAK_EXTRA_WORKED_TIME = "BREAK_EXTRA_WORKED_TIME",
}

export enum InstantPayPaymentVia {
  STRIPE = "STRIPE",
  DAILY_PAY = "DAILY_PAY",
}

export enum ShiftSpecificationCategory {
  GRADE_AND_SUBJECT = "GRADE_AND_SUBJECT",
  AGE_GROUP = "AGE_GROUP",
  PATIENT_SCHEDULE = "PATIENT_SCHEDULE",
  DRIVE_ROUTE = "DRIVE_ROUTE",
  ADDITIONAL_INFO = "ADDITIONAL_INFO",
}

export type NfcTagRequest = z.infer<typeof nfcTagRequestSchema>;

const instantPayShiftDetailsSchema = z.object({
  shiftId: z.string().optional(),
  hcpId: z.string().optional(),
  start: z.string().optional(),
  end: z.string().optional(),
  verified: z.boolean().optional(),
  deleted: z.boolean().optional(),
  status: z.nativeEnum(InstantPayTimeLogStates).optional(),
  action: z
    .union([
      z.nativeEnum(InstantPayTimeLogStates).optional(),
      z.array(z.nativeEnum(InstantPayTimeLogStates).optional()),
    ])
    .optional(),
  payments: z
    .array(
      z.object({
        amount: z.number(),
        paidVia: z.nativeEnum(InstantPayPaymentVia).optional(),
        type: z.nativeEnum(InstantPayPaymentTypes),
      })
    )
    .optional(),
  charge: z.number().optional(),
  time: z.number().optional(),
  pay: z.number().optional(),
  totalAmount: z.number().optional(),
  paidAmount: z.number().optional(),
  remaingAmount: z.number().optional(),
  hcpClockIn: z.string().optional(),
  hcpLunchOut: z.string().optional(),
  hcpLunchIn: z.string().optional(),
  hcpClockOut: z.string().optional(),
  adminClockIn: z.string().optional(),
  adminLunchIn: z.string().nullish(),
  adminLunchOut: z.string().nullish(),
  adminClockOut: z.string().optional(),
  completedDate: z.string().optional(),
  postVerifyClockIn: z.string().optional(),
  postVerifyClockOut: z.string().optional(),
  postVerifyLunchIn: z.string().optional(),
  postVerifyLunchOut: z.string().optional(),
  is100InstantPayEnabled: z.boolean().optional(),
  nfcTag: z.boolean().optional(),
  submitClockIn: z.string().optional(),
  submitLunchIn: z.string().nullish(),
  submitLunchOut: z.string().nullish(),
  submitClockOut: z.string().optional(),
});

export type InstantPayShiftDetails = z.infer<typeof instantPayShiftDetailsSchema>;

export const timecardSchema = z.object({
  createdAt: z.string().optional(),
  files: z.array(
    z.object({
      _id: z.string(),
      url: z.string(),
      signedType: z.string().nullish(),
      timesheet: z.string(),
      fileStorageFileKey: z.string(),
      filename: z.string(),
      fileStorageRootFolder: z.string().nullish(),
    })
  ),
  status: z.string().optional(),
});
export type Timecard = z.infer<typeof timecardSchema>;

export enum SignatoryStatus {
  REQUESTED = "REQUESTED",
  REJECTED = "REJECTED",
  SIGNED = "SIGNED",
}
export const requestedSignatorySchema = authorizedSignatorySchema.extend({
  status: z.nativeEnum(SignatoryStatus),
});

const timeRangeSchema = z.object({
  start: z.string().nullish(),
  end: z.string().nullish(),
  startCard: z.string().nullish(),
  endCard: z.string().nullish(),
  isSkipped: z.boolean().nullish(),
  startImage: z.string().nullish(),
  endImage: z.string().nullish(),
});

export const shiftSchema = z.object({
  _id: z.string(),
  start: z.string(),
  end: z.string(),
  agentReq: z.string(),
  name: z.nativeEnum(ShiftPeriod),
  time: z.number().optional(),
  pay: z.number().optional(),
  distance: z.number().optional(),
  isApproxDistance: z.boolean().optional(),
  charge: z.number().optional(),
  note: z.string().optional(),
  description: z.string().nullish(),
  surgerySpeciality: z
    .object({
      hasSedationExperience: z.boolean(),
      hasTrayAssemblyExperience: z.boolean(),
      speciality: z.string().nullish(),
    })
    .optional(),
  facilityId: z.string().optional(),
  facility: facilitySchema,
  agentId: z.string().nullish(),
  interested: z.array(z.string()).optional(),
  signed: z.boolean().optional(),
  verified: z.boolean().optional(),
  signatory: authorizedSignatorySchema.nullish(),
  requestedSignatories: z.array(requestedSignatorySchema).optional(),
  deleted: z.boolean().nullish(),
  clockInOut: timeRangeSchema.nullish(),
  lunchInOut: timeRangeSchema.nullish(),
  unit: z.string().nullish(),
  timecard: timecardSchema.optional(),
  instantPayDetails: instantPayShiftDetailsSchema.nullish(),
  isHolidayShift: z.boolean().optional(),
  isChangedToNonInstantPay: z.boolean().optional(),
  timecardNotAvailable: z
    .object({
      reason: z.string(),
      setBy: z.string(),
      updatedAt: z.string(),
    })
    .optional(),
  autoClockedOut: z.boolean().optional(),
  holidayName: z.string().nullish(),
  isHCFInstantPayProhibited: z.boolean().nullish(),
  isInstantPay: z.boolean().optional(),
  urgency: z.nativeEnum(UrgencyType).nullish(),
  urgentlyBooked: z.boolean().nullish(),
  priorityTill: z.string().nullish(),
  /**
   * payBreakDown and chargeBreakDown are deprecated
   * See: https://clipboardhealth.atlassian.net/browse/CH-22416
   * Use `isChargeUpdatedManually` instead.
   */
  isChargeUpdatedManually: z.boolean().optional(),
  facilityNotes: z
    .array(
      z.object({
        category: z.string(),
        identifier: z.string(),
        note: z.string(),
        noteId: z.string(),
        updatedAt: z.string(),
      })
    )
    .optional(),
  finalPay: z.number().optional(),
  originalAmount: z.number().nullish(),
  shiftId: z.string().optional(),
  isInstantBook: z.boolean().optional(),
  isInstantForAgent: z.boolean().optional(),
  isStripePayment: z.boolean().nullish(),
  nfcTag: z.boolean().nullish(),
  nfcTagRequests: z.array(nfcTagRequestSchema).nullish(),
  offer: z
    .object({
      id: z.string(),
      pay: z.object({
        value: z.number(),
        currency: z.string(),
      }),
      expiresAt: z.string().optional(),
    })
    .nullish(),
  shiftBlockId: z.string().nullish(),
  latestShiftClaimLog: z
    .object({
      shift: z.object({
        workerId: z.string(),
      }),
      eventAt: z.string(),
    })
    .optional(),
  cancellationWithoutPenaltyThresholdTime: z.string().optional(),
  shiftSpecifications: z
    .array(
      z.object({
        category: z.nativeEnum(ShiftSpecificationCategory),
        requirement: z.string(),
      })
    )
    .optional(),
  window: z.nativeEnum(ShiftWindow).nullish(),
});

export const workerShiftSchema = z
  .object({
    _id: z.string(),
    start: z.string(),
    end: z.string(),
    agentReq: z.string(),
    name: z.nativeEnum(ShiftPeriod),
    agentId: z.string(),
    isInstantPay: z.boolean(),
    facilityId: z.string(),
  })
  .merge(
    shiftSchema.pick({
      verified: true,
      deleted: true,
      clockInOut: true,
      lunchInOut: true,
      timecard: true,
      facility: true,
      nfcTag: true,
      nfcTagRequests: true,
      offer: true,
      finalPay: true,
      originalAmount: true,
    })
  );
export type WorkerShift = z.infer<typeof workerShiftSchema>;

export type Shift = z.infer<typeof shiftSchema>;

export const colleagueSchema = z.object({
  colleagueUserId: z.string(),
  qualification: z.string(),
  colleagueName: z.string(),
  shiftStart: z.string().optional(),
  facilityName: z.string().optional(),
  shiftName: z.nativeEnum(ShiftPeriod).optional(),
  hasProfilePic: z.boolean(),
  isFriend: z.boolean(),
  isFriendRequestSent: z.boolean().optional(),
});

export type Colleague = z.infer<typeof colleagueSchema>;

const shiftSelfCancelErrorSchema = z.object({
  status: z.string(),
  detail: z.string(),
  title: z.string(),
  code: z.string().nullish(),
  id: z.string(),
});

export const selfCancelShiftErrorResponseSchema = z.object({
  errors: z.array(shiftSelfCancelErrorSchema),
});

export type SelfCancelShiftErrorResponse = z.infer<typeof selfCancelShiftErrorResponseSchema>;

export enum RateNegotiationStatus {
  OPEN = "OPEN",
  EXPIRED = "EXPIRED",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  CLOSED = "CLOSED",
}

export type TimeRange = z.infer<typeof timeRangeSchema>;

export enum ExtraWorkedTimeType {
  BREAK = "BREAK",
}
