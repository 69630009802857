import { useFacilityProfile } from "@src/appV2/Facilities/api/useFacilityProfile";
import { type ShiftFeedbackQuestion } from "@src/appV2/Facilities/types";

export interface FeedbackOption {
  value: string;
  type?: string | undefined;
}

export const defaultFeedbackQuestion = {
  firstQuestion: {
    description: "Did this professional complete their assigned tasks today when possible?",
    subText: undefined,
  },
  mainQuestion: {
    description: "How did this professional do after arriving at your facility today?",
    negativeOptions: ["Could put in some extra effort", "Professionalism could be better"],
    otherOptions: ["Other", "Skip"],
    positiveOptions: ["Excellent attitude", "Great with patients"],
    subText: undefined,
  },
};

export const defaultNamePageBoldedMessage = "a workplace employee you worked with";

function mapShiftFeedbackQuestion(question: ShiftFeedbackQuestion) {
  const { otherOptions = [], positiveOptions = [], negativeOptions = [], ...rest } = question ?? {};
  const questionOptions: FeedbackOption[] = [
    ...positiveOptions.map((value) => ({ value, type: "POSITIVE" as const })),
    ...negativeOptions.map((value) => ({ value, type: "NEGATIVE" as const })),
    ...otherOptions.map((value) => ({ value })),
  ];
  return { ...rest, options: questionOptions };
}

export function useGetShiftFeedbackClockOutConfig(workplaceId: string) {
  const {
    data: facilityProfile,
    isLoading,
    isSuccess,
  } = useFacilityProfile({
    workplaceId,
    projection: "qrCodeForClockOutIsEnabled shiftFeedbackQrCodeConfiguration type",
  });
  const { qrCodeForClockOutIsEnabled = false, shiftFeedbackQrCodeConfiguration } =
    facilityProfile ?? {};
  const {
    questions = [],
    feedbackBeforeClockOutIsEnabled,
    namePageBoldedMessage = defaultNamePageBoldedMessage,
    ...shiftFeedbackQrCodeConfig
  } = shiftFeedbackQrCodeConfiguration ?? {};
  const mappedQuestions = questions.map((question) => mapShiftFeedbackQuestion(question));
  const isEnabled = mappedQuestions.length > 0;
  const facilityQrCodeForClockOutIsEnabled = qrCodeForClockOutIsEnabled && isEnabled;

  return {
    shiftFeedbackClockOutFlowIsEnabled: isEnabled,
    namePageBoldedMessage,
    questions: mappedQuestions,
    qrCodeForClockOutIsEnabled: facilityQrCodeForClockOutIsEnabled,
    qrCodeFeedbackBeforeClockOutIsEnabled:
      feedbackBeforeClockOutIsEnabled && facilityQrCodeForClockOutIsEnabled,
    isLoading,
    isSuccess,
    ...shiftFeedbackQrCodeConfig,
  };
}
